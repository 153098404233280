import { useEffect, useRef, useState } from "react";
import { observe, values } from "mobx";
import { ApplicationNotificationLevel } from "@polo/common/state/models/application-notification-model";
import { useNotification } from "@polo/common/state/models/notification-hook";
import { NotificationType } from "@polo/common/state/models/notification-model";
export const useApplicationNotifications = (application) => {
    const [notifications, setNotifications] = useState(() => Array.from(application.notifications.values()));
    const { notify } = useNotification();
    const showedNotifications = useRef({});
    useEffect(() => {
        setNotifications(Array.from(application.notifications.values()));
        const disposer = observe(application.notifications, ({ object }) => {
            setNotifications(values(object));
        });
        return disposer;
    }, [application]);
    useEffect(() => {
        const uniqueNotificationsByType = notifications
            .reduce((acc, notification) => {
            if (!acc[notification.type]) {
                acc[notification.type] = notification;
            }
            return acc;
        }, {});
        const uniqueNotificationByID = notifications
            .reduce((acc, notification) => {
            if (!acc[notification.uuid]) {
                acc[notification.uuid] = notification;
            }
            return acc;
        }, {});
        const uniqueNotifications = Object.values(uniqueNotificationsByType);
        Object.entries(showedNotifications.current)
            .forEach(([_, v]) => {
            const { appNotification, remove } = v;
            if (!uniqueNotificationByID[appNotification.uuid]) {
                remove();
                delete showedNotifications.current[appNotification.uuid];
            }
        });
        uniqueNotifications
            .forEach(notification => {
            if (!showedNotifications.current[notification.uuid]) {
                let type = NotificationType.INFO;
                switch (notification.level) {
                    case ApplicationNotificationLevel.CRITICAL:
                        type = NotificationType.ERROR;
                        break;
                }
                let expiration = notification.permanent ? 0 : 10;
                const appNotification = {
                    ...notify({
                        text: notification.description,
                        type,
                        expiration
                    }),
                    appNotification: notification
                };
                showedNotifications.current[notification.uuid] = appNotification;
            }
        });
    }, [notifications]);
};
