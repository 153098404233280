import React from 'react';
import './default-modal-layout.scss';
import classnames from 'classnames';
export const DefaultModalLayout = (props) => React.createElement("div", { className: classnames('default-modal-layout', props.className) }, props.children);
export const DefaultModalHeader = (props) => React.createElement("div", { className: classnames('__header', props.className) },
    React.createElement("div", null,
        props.title,
        props.children),
    props.subtitle && React.createElement("div", { className: "__subtitle" }, props.subtitle));
export const DefaultModalList = (props) => React.createElement("div", { className: classnames('__list', props.className) }, props.children);
export const DefaultModalItem = ({ dangerIcon = false, disabled = false, action = false, categoryHeader = false, multipleRows = false, showOnMobile = false, notImplemented = false, indented = false, className, onClick, children }) => React.createElement("a", { className: classnames('__item', className, {
        '--danger-icon': dangerIcon,
        '--disabled': disabled,
        '--action': action,
        '--category-header': categoryHeader,
        '--multiple-rows': multipleRows,
        '--show-on-mobile': showOnMobile,
        '--not-implemented': notImplemented,
        '--indented': indented,
    }), onClick: onClick }, children);
export const DefaultModalRow = ({ indented = false, secondary = false, className, children }) => React.createElement("div", { className: classnames('__row', className, {
        '--indented': indented,
        '--secondary': secondary,
    }) }, children);
export const DefaultModalDivider = (props) => React.createElement("div", { className: classnames('flex justify-center my-2', props.className) },
    React.createElement("div", { className: "border-t border-gray-500 w-full opacity-40", style: { height: 1 } }));
