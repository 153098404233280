import React from 'react';
import { DefaultModal } from '../modal/default-modal';
import { DefaultModalHeader, DefaultModalLayout } from '../modal/default-modal-layout/default-modal-layout';
import { CommitMessage } from './commit-message';
export const CommitModal = (props) => {
    return React.createElement(DefaultModal, { name: props.name },
        React.createElement(DefaultModalLayout, null,
            React.createElement(DefaultModalHeader, null, props.title),
            React.createElement("div", { className: "p-3" },
                React.createElement(CommitMessage, { ...props }))));
};
