import React from 'react';
import { DefaultModal } from '@/components/modal/default-modal';
import { DefaultModalDivider, DefaultModalHeader, DefaultModalItem, DefaultModalLayout, DefaultModalList } from '@/components/modal/default-modal-layout/default-modal-layout';
import { AnnotationIcon } from '@polo/common/components/elements/icons/annotation/annotation-icon';
import { BeakerIcon } from '@polo/common/components/elements/icons/beaker/beaker-icon';
import { ClipboardIcon } from '@polo/common/components/elements/icons/clipboard/clipboard-icon';
import { CodeIcon } from '@polo/common/components/elements/icons/code/code-icon';
import { CubeIcon } from '@polo/common/components/elements/icons/cube/cube-icon';
import { LoginIcon } from '@polo/common/components/elements/icons/login/login-icon';
import { TextDocumentIcon } from '@polo/common/components/elements/icons/text-document/text-document-icon';
import { TrashIcon } from '@polo/common/components/elements/icons/trash/trash-icon';
import { LinkIcon } from '@heroicons/react/outline';
export const ApplicationSessionModal = (props) => {
    return React.createElement(DefaultModal, { name: props.name },
        React.createElement(DefaultModalLayout, null,
            React.createElement(DefaultModalHeader, { title: props.session.displayName, subtitle: props.session.uuid }),
            React.createElement(DefaultModalList, null,
                React.createElement(DefaultModalItem, { onClick: props.onEnterSessionSelect },
                    React.createElement(LoginIcon, null),
                    React.createElement("span", null, "Enter the session")),
                React.createElement(DefaultModalDivider, null),
                props.session.smartURL && React.createElement(DefaultModalItem, { onClick: props.onCopySmartURLSelect },
                    React.createElement(ClipboardIcon, null),
                    React.createElement("span", null, "Copy link")),
                React.createElement(DefaultModalItem, { onClick: props.onCopyPermalinkSelect },
                    React.createElement(LinkIcon, null),
                    React.createElement("span", null, "Copy permalink")),
                React.createElement(DefaultModalItem, { onClick: () => props.onCommitMessageSelect(props.session) },
                    React.createElement(AnnotationIcon, null),
                    React.createElement("span", null, "View commit message")),
                React.createElement(DefaultModalItem, { onClick: () => props.onShowLogsSelect(props.session) },
                    React.createElement(TextDocumentIcon, null),
                    React.createElement("span", null, "View build logs")),
                React.createElement(DefaultModalDivider, null),
                React.createElement(DefaultModalItem, { onClick: () => props.onAPISelect(props.session) },
                    React.createElement(CodeIcon, null),
                    React.createElement("span", null, "JSON API")),
                props.session.beingReplacedBySession && React.createElement(React.Fragment, null,
                    React.createElement(DefaultModalDivider, null),
                    React.createElement(DefaultModalItem, { categoryHeader: true }, "Replacement build"),
                    React.createElement(DefaultModalItem, { indented: true, onClick: () => props.onCommitMessageSelect(props.session.beingReplacedBySession) },
                        React.createElement(AnnotationIcon, null),
                        React.createElement("span", null, "View commit message")),
                    React.createElement(DefaultModalItem, { indented: true, onClick: () => props.onShowLogsSelect(props.session.beingReplacedBySession) },
                        React.createElement(TextDocumentIcon, null),
                        React.createElement("span", null, "View build logs")),
                    React.createElement(DefaultModalItem, { indented: true, onClick: () => props.onAPISelect(props.session.beingReplacedBySession) },
                        React.createElement(CodeIcon, null),
                        React.createElement("span", null, "JSON API"))),
                React.createElement(DefaultModalItem, { notImplemented: true },
                    React.createElement(CodeIcon, null),
                    React.createElement("span", null, "Advanced commands")),
                React.createElement(DefaultModalItem, { notImplemented: true },
                    React.createElement(BeakerIcon, null),
                    React.createElement("span", null, "Custom commands")),
                React.createElement(DefaultModalDivider, { className: "hidden" }),
                React.createElement(DefaultModalItem, { notImplemented: true },
                    React.createElement(CubeIcon, null),
                    React.createElement("span", null, "Rebuild")),
                React.createElement(DefaultModalDivider, null),
                React.createElement(DefaultModalItem, { onClick: () => props.onSessionDeletionSelect() },
                    React.createElement(TrashIcon, null),
                    React.createElement("span", null, "Delete")))));
};
