import React, { useState } from 'react';
import './application.scss';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { APIRequestResult } from '@polo/common/api/common';
import { SessionSubscriptionEventType } from '@polo/common/state/models';
import { ApplicationCheckouts } from './checkouts/application-checkouts';
import { ApplicationSessions } from './sessions/application-sessions';
import { ApplicationHeader } from './header/application-header';
import { Button } from '@polo/common/components/elements/button/button';
import { CubeIcon } from '@polo/common/components/elements/icons/cube/cube-icon';
import { useSubscription } from '@polo/common/state/models/subscription-hook';
import { NotificationType } from '@polo/common/state/models/notification-model';
import { useNotification } from '@polo/common/state/models/notification-hook';
import { buildFailedNotification } from '@polo/common/state/notifications/build-failed-notification';
import { ApplicationNotifications } from './notifications/application-notifications';
export const Application = observer((props) => {
    const [newSessionCheckout, setNewSessionCheckout] = useState("");
    const { subscribe } = useSubscription();
    const { notify } = useNotification();
    const history = useHistory();
    const onCheckoutChange = (value) => setNewSessionCheckout(value);
    const submitSessionCreation = async (checkout) => {
        if (!checkout)
            return;
        checkout = checkout.trim();
        if (checkout) {
            const newSession = await props.application.newSession(checkout);
            if (newSession.result === APIRequestResult.SUCCEEDED) {
                subscribe(newSession.payload.uuid, SessionSubscriptionEventType.FAIL, session => {
                    notify(buildFailedNotification(session, notification => {
                        notification.remove();
                        history.push(`/_polo_/session/failing/${session.uuid}`);
                    }));
                });
                history.push(`/_polo_/session/${newSession.payload.uuid}/`);
            }
            else {
                notify({
                    text: 'Could not create new session.\n' + newSession.reason,
                    type: NotificationType.ERROR
                });
            }
        }
    };
    return React.createElement("div", { className: `
        px-0
        mx-auto
        font-quicksand
        application` },
        React.createElement(ApplicationNotifications, { application: props.application }),
        React.createElement("div", { className: "hidden lg:block" },
            React.createElement(ApplicationHeader, { name: props.application.configuration.name, filename: props.application.filename, failures: props.failures })),
        React.createElement("div", { className: "lg:hidden" },
            React.createElement(ApplicationHeader, { name: props.application.configuration.name, filename: props.application.filename, failures: props.failures, showApplicationSelector: props.moreThanOneApplication, onApplicationsSelectorClick: props.onApplicationsSelectorClick })),
        props.sessions && props.sessions.length > 0 && React.createElement("div", { className: "py-4" },
            React.createElement(ApplicationSessions, { sessions: props.sessions })),
        props.application.branchesMap.size > 0 && React.createElement("div", { className: "py-4" },
            React.createElement(ApplicationCheckouts, { branches: props.application.branchesMap, tags: props.application.tagsMap, onSessionCreationSubmission: submitSessionCreation })),
        React.createElement("div", { className: "mt-7 mb-0 flex justify-center" },
            React.createElement("div", { className: "min-w-9/12 h-1 border-b border-gray-300 dark:border-gray-500" })),
        React.createElement("div", { className: "flex my-4 py-4 px-2 lg:px-6 flex-col" },
            React.createElement("span", { className: "text-sm text-gray-500 opacity-80 mb-2" }, "Or write down the commit you want to build."),
            React.createElement("div", { className: "flex items-center __input-container" },
                React.createElement("input", { className: "", type: "text", placeholder: "Commit, branch or tag", value: newSessionCheckout, onChange: e => onCheckoutChange(e.target.value), onKeyUp: e => e.key === 'Enter' && submitSessionCreation(newSessionCheckout) }),
                React.createElement(Button, { success: true, small: true, onClick: () => submitSessionCreation(newSessionCheckout), label: "Create", icon: React.createElement(CubeIcon, null) }))));
});
