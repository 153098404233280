import React, { useState } from 'react';
import { DefaultModal } from '@/components/modal/default-modal';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useHistory } from 'react-router';
import { DefaultModalHeader, DefaultModalItem, DefaultModalLayout, DefaultModalList, DefaultModalRow } from '@/components/modal/default-modal-layout/default-modal-layout';
import { ExclamationCircleIcon } from '@polo/common/components/elements/icons/exclamation-circle/exclamation-circle-icon';
import { LeftArrowIcon } from '@polo/common/components/elements/icons/left-arrow/left-arrow-icon';
import { TextDocumentIcon } from '@polo/common/components/elements/icons/text-document/text-document-icon';
import { ClockIcon } from '@polo/common/components/elements/icons/clock/clock-icon';
import { FailureStatus } from '@polo/common/state/models/failures-model';
dayjs.extend(relativeTime);
export const ApplicationOptionsModal = observer((props) => {
    const [viewFailingSessions, setViewFailingSession] = useState(false);
    const anyFailures = props.failures && (props.failures[FailureStatus.ACK].length > 0 ||
        props.failures[FailureStatus.UNACK].length > 0);
    const anyUnacknowledgedFailures = props.failures &&
        props.failures[FailureStatus.UNACK].length > 0;
    const history = useHistory();
    const failures = sortSessionsByCreationTimeDesc(props.failures);
    return React.createElement(DefaultModal, { name: props.modalName },
        React.createElement(DefaultModalLayout, null,
            React.createElement(DefaultModalHeader, null, props.applicationName),
            !viewFailingSessions && React.createElement(DefaultModalList, null,
                React.createElement(DefaultModalItem, { dangerIcon: anyUnacknowledgedFailures, disabled: !anyFailures, onClick: () => anyFailures && setViewFailingSession(true) },
                    React.createElement(ExclamationCircleIcon, null),
                    React.createElement("span", null, "View failing sessions"))),
            viewFailingSessions && React.createElement(DefaultModalList, null,
                React.createElement(DefaultModalItem, { action: true, onClick: () => setViewFailingSession(false) },
                    React.createElement(LeftArrowIcon, null),
                    React.createElement("span", { className: "font-bold" }, "Go back")),
                failures.map(({ session, status }, index) => React.createElement(DefaultModalItem, { key: index, dangerIcon: status === FailureStatus.UNACK, multipleRows: true, onClick: () => props.onSessionClick(session) },
                    React.createElement(DefaultModalRow, null,
                        React.createElement(TextDocumentIcon, null),
                        React.createElement("span", null, session.commitMessage.split('\n')[0])),
                    React.createElement(DefaultModalRow, { secondary: true, indented: true },
                        React.createElement(ClockIcon, null),
                        React.createElement("span", { className: "text-gray-400 text-sm" }, dayjs(session.createdAt).fromNow())))))));
});
function sortSessionsByCreationTimeDesc(failures) {
    if (!failures)
        return [];
    const sessions = [];
    for (const session of failures.acknowledged) {
        sessions.push({
            session,
            status: FailureStatus.ACK,
        });
    }
    for (const session of failures.unacknowledged) {
        sessions.push({
            session,
            status: FailureStatus.UNACK,
        });
    }
    return sessions
        .sort((a, b) => {
        const dateA = dayjs(a.session.createdAt);
        const dateB = dayjs(b.session.createdAt);
        if (dateA.isBefore(dateB))
            return 1;
        if (dateA.isAfter(dateB))
            return -1;
        return 0;
    });
}
