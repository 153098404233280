import React from 'react';
import { DefaultModal } from '@/components/modal/default-modal';
import { observer } from 'mobx-react-lite';
import { DefaultModalDivider, DefaultModalHeader, DefaultModalItem, DefaultModalLayout, DefaultModalList, } from '@/components/modal/default-modal-layout/default-modal-layout';
import { CollectionIcon } from "@heroicons/react/outline";
export const ApplicationSelectorModal = observer((props) => {
    return React.createElement(DefaultModal, { name: props.modalName },
        React.createElement(DefaultModalLayout, null,
            React.createElement(DefaultModalHeader, null, "Applications"),
            React.createElement(DefaultModalList, null, props.applications.map((application, index) => React.createElement(React.Fragment, { key: application.configuration.hash },
                React.createElement(DefaultModalItem, { onClick: () => props.onApplicationClick(application.configuration.name, index) },
                    React.createElement(CollectionIcon, null),
                    React.createElement("span", null, application.configuration.name)),
                React.createElement(DefaultModalDivider, null))))));
});
