import { store } from "@polo/common/state/models";
export const useModal = () => {
    const show = (name) => {
        store.app.modal.setVisible(true, name);
    };
    const hide = () => {
        store.app.modal.setVisible(false);
    };
    return { show, hide };
};
