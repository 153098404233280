import { APIRequestResult } from '@polo/common/api/common';
import { SessionStatus } from '@polo/common/state/models/session-model-enums';
import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
dayjs.extend(relativeTime);
dayjs.extend(duration);
import './application-session.scss';
import { observer } from 'mobx-react-lite';
import { useModal } from '@/components/modal/modal-hooks';
import { CommitModal } from '../../../shared/commit-modal';
import { ApplicationSessionModal } from './modal/application-session-modal';
import { ApplicationSessionDeletionModal } from './modal/application-session-deletion-modal';
import { useClipboard } from '@polo/common/components/hooks/use-clipboard';
import { useHistory } from 'react-router-dom';
import loading from '@/assets/loading.svg';
import { Button } from '@polo/common/components/elements/button/button';
import { LoginIcon } from '@polo/common/components/elements/icons/login/login-icon';
import { HorizontalDotsIcon } from '@polo/common/components/elements/icons/horizontal-dots/horizontal-dots-icon';
export const validAgeValue = 1;
export const ApplicationSession = observer((props) => {
    const { show, hide } = useModal();
    const copy = useClipboard();
    const history = useHistory();
    const getOptionsModalName = (uuid = props.session.uuid) => `session-${uuid}`;
    const deleteSessionModalName = `${getOptionsModalName()}-session-deletion`;
    const getCommitMessageModalName = (uuid = props.session.uuid) => `${getOptionsModalName(uuid)}-commit`;
    const attachToSession = async () => {
        const track = await props.session.track();
        if (track.result === APIRequestResult.SUCCEEDED) {
            location.href = '/';
        }
    };
    const killSession = async (session) => {
        hide();
        await session.kill();
    };
    const copySmartURL = () => {
        copy(`${location.origin}${props.session.smartURL}`);
        hide();
    };
    const copyPermalink = () => {
        copy(`${location.origin}${props.session.permalink}`);
        hide();
    };
    const showLogs = (session) => {
        if (!session)
            return;
        hide();
        history.push(`/_polo_/session/${session.uuid}/logs`);
    };
    const openAPIDocument = (session) => {
        if (!session)
            return;
        window.open(`/_polo_/api/session/${session.uuid}`, '_blank');
    };
    const openCommitModal = (session) => {
        if (!session)
            return;
        hide();
        show(getCommitMessageModalName(session.uuid));
    };
    const showLoadingIcon = props.session.status === SessionStatus.STARTING ||
        props.session.beingReplacedBySession;
    const showStartedIcon = props.session.status === SessionStatus.STARTED && !showLoadingIcon;
    return React.createElement("div", { className: "application-session" },
        React.createElement("a", { className: "__content", onClick: attachToSession },
            React.createElement("div", { className: "w-6 flex mr-2" },
                showStartedIcon &&
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: colorByStatus(props.session.status), className: "w-6 h-6 mt-0.5" },
                        React.createElement("path", { fillRule: "evenodd", d: "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z", clipRule: "evenodd" })),
                showLoadingIcon && React.createElement("img", { src: loading, className: "w-4 mt-1 ml-1" })),
            React.createElement("div", { className: "__title-container" },
                React.createElement("span", { className: "__title" }, props.session.displayName),
                React.createElement("div", { className: "__subtitle-container" },
                    React.createElement("span", { className: "__subtitle-item" },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", className: "w-3 h-3 mr-1" },
                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" })),
                        React.createElement("span", { className: "whitespace-nowrap" }, dayjs(props.session.createdAt).fromNow())),
                    props.session.age >= validAgeValue && React.createElement("span", { className: "__subtitle-item" },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", className: "w-3 h-3 mr-1" },
                            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" })),
                        React.createElement("span", { className: "whitespace-nowrap" }, dayjs.duration(formatAge(props.session.age)).format('HH:mm:ss')))))),
        React.createElement("span", { className: "text-center whitespace-nowrap flex flex-nowrap items-start" },
            React.createElement(Button, { success: true, hideOnMobile: true, onClick: attachToSession, label: "Enter", icon: React.createElement(LoginIcon, null) }),
            React.createElement(Button, { ghost: true, onClick: () => show(getOptionsModalName()), icon: React.createElement(HorizontalDotsIcon, null) })),
        React.createElement(ApplicationSessionModal, { session: props.session, name: getOptionsModalName(), onAPISelect: openAPIDocument, onCommitMessageSelect: openCommitModal, onEnterSessionSelect: attachToSession, onSessionDeletionSelect: () => show(deleteSessionModalName), onCopySmartURLSelect: copySmartURL, onCopyPermalinkSelect: copyPermalink, onShowLogsSelect: showLogs }),
        React.createElement(CommitModal, { name: getCommitMessageModalName(), title: props.session.displayName, commitAuthorEmail: props.session.commitAuthorEmail, commitAuthorName: props.session.commitAuthorName, commitDate: props.session.commitDate, commitMessage: props.session.commitMessage }),
        props.session.beingReplacedBySession && React.createElement(CommitModal, { name: getCommitMessageModalName(props.session.beingReplacedBySession.uuid), title: props.session.beingReplacedBySession.displayName, commitAuthorEmail: props.session.beingReplacedBySession.commitAuthorEmail, commitAuthorName: props.session.beingReplacedBySession.commitAuthorName, commitDate: props.session.beingReplacedBySession.commitDate, commitMessage: props.session.beingReplacedBySession.commitMessage }),
        React.createElement(ApplicationSessionDeletionModal, { name: deleteSessionModalName, session: props.session, onApplicationDeletionSelected: () => killSession(props.session) }));
});
function formatAge(seconds) {
    const secondsInHour = 60 * 60;
    const hours = Math.floor(seconds / secondsInHour);
    seconds = seconds - (hours * secondsInHour);
    const minutes = Math.floor(seconds / 60);
    seconds = seconds - (minutes * 60);
    return { hours, minutes, seconds };
}
function colorByStatus(status) {
    switch (status) {
        case SessionStatus.STARTED:
            return '#a3be8c';
        case SessionStatus.STARTING:
        case SessionStatus.DEGRADED:
            return '#ebcb8b';
        case SessionStatus.STOPPING:
            return '#d08770';
        case SessionStatus.START_FAILED:
        case SessionStatus.STOP_FAILED:
            return '#bf616a';
    }
}
