import { store } from '@polo/common/state/models';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { useModal } from './modal-hooks';
import './modal.scss';
export const ModalContainer = observer((props) => {
    const { hide } = useModal();
    useLayoutEffect(() => {
        const listener = (e) => {
            if (e.key === 'Escape')
                hide();
        };
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, []);
    const disposeModal = () => store.app.modal.setVisible(false);
    return React.createElement("div", { className: "modal", onClick: () => disposeModal() },
        React.createElement("div", { className: "__content", onClick: e => e.stopPropagation() }, props.children));
});
export const ModalPortal = observer((props) => {
    return (props.modal.visible &&
        props.modal.name === props.name &&
        createPortal(React.createElement(ModalContainer, null, props.children), document.getElementById('modal'))) || null;
});
export const Modal = (props) => {
    const { hide } = useModal();
    useEffect(() => hide, []);
    return React.createElement(ModalPortal, { modal: store.app.modal, name: props.name }, props.children);
};
