import { DefaultModal } from '@/components/modal/default-modal';
import { useModal } from '@/components/modal/modal-hooks';
import { CommitMessage } from '@/components/shared/commit-message';
import { Button } from '@polo/common/components/elements/button/button';
import { CubeIcon } from '@polo/common/components/elements/icons/cube/cube-icon';
import React from 'react';
import './checkout-build-confirmation-modal.scss';
export const CheckoutBuildConfirmationModal = (props) => {
    const { hide } = useModal();
    return React.createElement(DefaultModal, { name: props.name },
        React.createElement("div", { className: "checkout-build-confirmation-modal" },
            React.createElement("div", { className: "__header mb-6" },
                React.createElement("div", { className: "text-base lg:text-lg font-bold whitespace-nowrap overflow-hidden overflow-ellipsis" }, props.checkoutName)),
            React.createElement(CommitMessage, { commitAuthorEmail: props.commitAuthorEmail, commitAuthorName: props.commitAuthor, commitDate: props.commitDate, commitMessage: props.commitMessage }),
            React.createElement("div", { className: "__actions-container" },
                React.createElement(Button, { success: true, outlined: true, icon: React.createElement(CubeIcon, null), label: "Create session", onClick: () => {
                        hide();
                        props.onSessionCreationSubmission(props.checkoutName);
                    } }))));
};
