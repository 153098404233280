import React from 'react';
import { DefaultModal } from '@/components/modal/default-modal';
import { DefaultModalDivider, DefaultModalHeader, DefaultModalItem, DefaultModalLayout, DefaultModalList } from '@/components/modal/default-modal-layout/default-modal-layout';
import { AnnotationIcon } from '@polo/common/components/elements/icons/annotation/annotation-icon';
import { CubeIcon } from '@polo/common/components/elements/icons/cube/cube-icon';
export const ApplicationCheckoutModal = (props) => {
    return React.createElement(DefaultModal, { name: props.name },
        React.createElement(DefaultModalLayout, null,
            React.createElement(DefaultModalHeader, null, props.checkoutName),
            React.createElement(DefaultModalList, null,
                React.createElement(DefaultModalItem, { showOnMobile: true, onClick: props.onSessionCreationSubmission },
                    React.createElement(CubeIcon, null),
                    React.createElement("span", null, "Create session")),
                React.createElement(DefaultModalDivider, { className: "sm:hidden" }),
                React.createElement(DefaultModalItem, { onClick: props.onCommitMessageSelection },
                    React.createElement(AnnotationIcon, null),
                    React.createElement("span", null, "View commit message")))));
};
