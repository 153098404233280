import React from 'react';
import { DefaultModal } from '@/components/modal/default-modal';
import './application-session-deletion-modal.scss';
import { Button } from '@polo/common/components/elements/button/button';
import { TrashIcon } from '@polo/common/components/elements/icons/trash/trash-icon';
export const ApplicationSessionDeletionModal = (props) => {
    return React.createElement(DefaultModal, { name: props.name },
        React.createElement("div", { className: "application-session-deletion-modal" },
            React.createElement("div", { className: "__header" },
                React.createElement("div", { className: "text-base lg:text-lg font-bold whitespace-nowrap overflow-hidden overflow-ellipsis" }, props.session.displayName),
                React.createElement("div", { className: "text-xs text-gray-500 dark:text-gray-400 opacity-80" }, props.session.uuid)),
            React.createElement("div", { className: "__description" }, "You are going to delete the session. Are you sure?"),
            React.createElement("div", { className: "__actions-container mt-5 flex justify-center" },
                React.createElement(Button, { danger: true, outlined: true, label: "Delete", onClick: props.onApplicationDeletionSelected, icon: React.createElement(TrashIcon, null) }))));
};
