import { useModal } from '@/components/modal/modal-hooks';
import React from 'react';
import { ApplicationOptionsModal } from '../options/application-options-modal';
import './application-header.scss';
import { useHistory } from 'react-router';
import { Button } from '@polo/common/components/elements/button/button';
import { MenuIcon } from '@polo/common/components/elements/icons/menu/menu-icon';
export const ApplicationHeader = (props) => {
    const { show, hide } = useModal();
    const history = useHistory();
    const anyUnacknowledged = props.failures && props.failures.unacknowledged.length > 0;
    const applicationOptionsModalName = `application-options-${props.name}`;
    const goTo = (session) => {
        hide();
        history.push(`/_polo_/session/failing/${session.uuid}`);
    };
    const onApplicationHeaderClick = () => {
        if (props.showApplicationSelector) {
            props.onApplicationsSelectorClick?.();
        }
    };
    const onApplicationOptionsClick = (event) => {
        event.stopPropagation();
        show(applicationOptionsModalName);
    };
    return React.createElement("div", { className: "application-header" },
        React.createElement("div", { className: "flex justify-between min-w-0 max-w-full flex-nowrap" },
            React.createElement("div", { className: "flex-grow flex-shrink pr-6 min-w-0", onClick: onApplicationHeaderClick },
                React.createElement("h3", { className: "text-xl lg:text-2xl leading-10 font-bold overflow-hidden overflow-ellipsis whitespace-nowrap __title", title: props.name }, props.name),
                React.createElement("span", { className: "text-gray-400 text-sm __filename" }, props.filename)),
            React.createElement(Button, { ghost: true, largeIcon: true, onClick: onApplicationOptionsClick, icon: React.createElement(MenuIcon, null) }, anyUnacknowledged && React.createElement("div", { className: "__error-circle" }))),
        React.createElement(ApplicationOptionsModal, { modalName: applicationOptionsModalName, applicationName: props.name, failures: props.failures, onSessionClick: goTo }));
};
